@import "minima";

$content-width:800px;
$link-color: #0082c9;
$bg-color: white;
$txt-color: #333;
$border-color: #e8e8e8;

$header-height: 10vh;

.scrolled{

}

.fullscreen{
    width: 100%;
    background-color: black;
    min-height: calc(100vh - #{$header-height});
    margin-top: $header-height;
    display: flex;
    justify-content: center;
    align-items:center;
}

header.site-header{

  position: fixed;
  width: 100vw;
  border-top: none;
  min-height: $header-height;
  display: flex;
  align-items: center;
  background-color: black;//$bg-color;
  border-bottom: 2px solid $txt-color;
  a, a:visited {
    color : $txt-color;
  }
  img{
    height: calc(#{$header-height / 1.5});
  }
  .wrapper::after{
      content: none;
  }
  .site-title{
    @include relative-font-size(1.2);
    float:none;
  }

  .wrapper {
    width: $content-width;
    display: flex;
    justify-content: space-between;

  }

  .site-nav .page-link{
    color: #2a7ae2;
    letter-spacing : 0.02em;
    font-weight: bold;
    padding: 5px;
    font-variant: small-caps;
    @include relative-font-size(1.2);
    &:hover{
	text-decoration: none;
	background-color: #2a7ae2;
	color:white;
    }
  }
}

body{
  @include relative-font-size(1.1);

  h2, h3 {
       letter-spacing : 0.02em;
  }
  h3{
    font-weight: bold;
  }

  .blog {
      .post-list li {
          p{
            margin-bottom: 10px
          }
      }
      h3{
        margin-bottom: 5px;
        border-bottom: 1px solid $border-color;
        font-weight: normal;
      }
      h3 a, h3 a:visited {
        width: 50%;
      }

      background-color: $bg-color;
      color: $txt-color;

      .post-meta-tags-cats {
        margin-top:5px;
        @include relative-font-size(0.8);

        .post-meta::after{
          color: $txt-color;
          content: " / ";
        }

        .post-meta-category:not(:last-child)::after{
          content: " · ";
        }
      }



      .read-more{
        @include relative-font-size(0.8);
      }
    }
}

h2 {
    font-weight: bold;
    margin-top: 50px;
}

h3{
    @include relative-font-size(1.1);
    margin-top: 40px;
}

.page-content{
    margin-top: $header-height;
}

.home-page-content{
    margin-top: 10px;
    @include relative-font-size(1.1);
}

.logo-home {
  display:flex;
  flex-direction: column;
  color: white;
  img{
    width: 400px;
  }

  h2{
    @include relative-font-size(1.2);
    letter-spacing: 0.06em;
  }
}

.post-list{
  @include relative-font-size(1.1);
  p{
    @include relative-font-size(1);
    line-height: 200%;
  }
}

.post-content{
  @include relative-font-size(1.1);
  h3{
    @include relative-font-size(1.2);
    margin-top:40px;
  }
}

.wrapper{
  max-width: $content-width;
}

.services-list{
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;


  li{
    display: flex;
    flex-direction: column;
    flex: 0 0 30;
    min-height:200px;
    width: 30%;
    max-width: 30%;
    margin: 5px;
    border: 1px solid #ccc;
    justify-content: space-between;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    &:hover{
      box-shadow: none;
    }
    a{
      display : flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      &:hover{
        box-shadow: none;
        text-decoration: none;
        }
      }

      span{
        @include relative-font-size(0.8);
        text-align: center;
      }
  }
}

.cgu{
  text-align: center;
}

img.inline-logo{
  width: 42px;
}
